import { graphql } from "gatsby"
import React from "react"
import { BlogIndexQuery } from "../../graphql-types"
import { BlogPost } from "../components/BlogPost"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { PageTitle } from "../components/PageTitle"

interface BlogIndexProps {
  data: BlogIndexQuery
  location: Location
}

type Posts = BlogIndexQuery["allMarkdownRemark"]["edges"]

const BlogIndex: React.FC<BlogIndexProps> = props => {
  const { data } = props
  const siteTitle = data.site?.siteMetadata?.title || ""
  const posts: Posts = data.allMarkdownRemark.edges

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="All posts" />
      <PageTitle>Things I&apos;ve written</PageTitle>
      {posts.map(({ node }, key: number) => {
        return <BlogPost key={node.fields?.slug || key} {...node} />
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndex {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
