import React from "react"
import styled from "styled-components"
import Heading from "../../assets/elements/heading"
import { theme, device } from "../../utils/theme"

const StyledHeader = styled.header`
  margin-bottom: ${theme.spacing * 6}px;

  @media ${device.desktop.s} {
    margin-bottom: ${theme.spacing * 8}px;
  }

  &::after {
    content: "";
    display: block;
    height: 4px;
    width: 63px;
    background: var(--heading);
    margin-top: ${theme.spacing * 3}px;

    @media ${device.desktop.s} {
      margin-top: ${theme.spacing * 8}px;
    }
  }
`

export const PageTitle: React.FC = ({ children }) => (
  <StyledHeader>
    <Heading size={1}>{children}</Heading>
  </StyledHeader>
)
