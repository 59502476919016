import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  MarkdownRemark,
  MarkdownRemarkFields,
  MarkdownRemarkFrontmatter,
  Maybe,
} from "../../../graphql-types"
import { theme } from "../../utils/theme"
import { PostDate } from "../PostDate"
import Heading from "../../assets/elements/heading"

type Node = Pick<MarkdownRemark, "excerpt"> & {
  fields: Maybe<Pick<MarkdownRemarkFields, "slug">>
  frontmatter: Maybe<
    Pick<MarkdownRemarkFrontmatter, "date" | "title" | "description">
  >
}

const StyledLink = styled(Link)`
  color: var(--link-color);
  &:hover {
    color: var(--link-color);
  }
`

const StyledArticle = styled.article`
  margin-bottom: ${theme.spacing * 6}px;
`

export const BlogPost: React.FC<Node> = (node: Node) => {
  const title = node.frontmatter?.title || node.fields?.slug

  return (
    <StyledArticle>
      <header>
        {node.fields?.slug && (
          <Heading size={2}>
            {node.fields?.slug && (
              <StyledLink to={node.fields?.slug}>{title}</StyledLink>
            )}
          </Heading>
        )}
        {node.frontmatter?.date && <PostDate>{node.frontmatter.date}</PostDate>}
      </header>
      {node.frontmatter?.description ||
        (node.excerpt && (
          <section>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter?.description || node.excerpt,
              }}
            />
          </section>
        ))}
      {/* {node.fields?.slug && <Anchor to={node.fields?.slug}>Read more</Anchor>} */}
    </StyledArticle>
  )
}
