import React from "react"
import styled from "styled-components"

const StyledDate = styled.small`
  font-family: "Cascadia";
  opacity: 0.5;
`

export const PostDate: React.FC = ({ children }) => (
  <StyledDate>{children}</StyledDate>
)
